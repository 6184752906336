.container {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.safe {
  border-radius: 8px;
}

.open {
  padding-left: 16px;
  border-left: 6px solid var(--color-secondary-light);
  background-color: var(--color-secondary-background) !important;
}

.safe:hover {
  background-color: var(--color-secondary-background);
}
