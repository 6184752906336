.banner {
  background-color: var(--color-info-dark);
  color: var(--color-text-light);
  padding: 0 var(--space-3);
  font-size: 16px;
}

.banner a {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--header-inner-height);
}

.content {
  max-width: 960px;
  text-align: center;
}

.close {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 2;
}

@media (max-width: 900px) {
  .close {
    position: relative;
    right: unset;
    top: unset;
  }
}

@media (max-width: 600px) {
  .banner {
    padding: var(--space-1) var(--space-2);
  }
}
